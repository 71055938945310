@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$header-height: 48px;

body {
  font-size: .875rem;

  &.route_user_edit {
    .internal-tags {
      .list-group-item {
        display: flex;

        select {
          margin-right: .5rem;
        }
      }
    }
  }
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.icon-large {
  width: 24px;
  height: 24px;
}

.sidebar {
  .nav-link .feather {
    margin-right: 4px;
  }

  .nav-link.active {
    color: $primary;
  }

  .nav-link {
    font-weight: $font-weight-normal + 100;
    color: $gray-900;
  }
}

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: absolute;
  top: $header-height;
  height: calc(100vh - #{$header-height});
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.dropdown-item-sm {
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  font-size: $font-size-sm;
}

.breadcrumb {
  margin-bottom: 1.5 * $spacer;
}

table.with-actions {
  th:last-child {
    width: 1px;
  }
}

.modal-busy {
  position: absolute;;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: $modal-backdrop-opacity;
  background-color: $white;
}

.btn-icon {
  display: flex;
  padding: 0;
  background-color: transparent;
}

.organization-image {
  box-sizing: content-box;
}

.button-loader:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: $black transparent $black transparent;
  animation: button-loader 1.2s linear infinite;
}

@keyframes button-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
